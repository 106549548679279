.sidebar-container {
  height: 100vh;
  border-right: 2px solid var(--lightBlue);
  /* transition: width 0.1s ease-in-out; */
}
.logo-container {
  padding-top: 42px;
  padding-bottom: 36px;
  text-align: center;
}
.open-transition {
  transition: opacity 0.5s ease-in-out;
}

.close-transition {
  transition: opacity 0.5s ease-in-out;
}

.borderdiv {
  /* width: 90%; */
  background: var(--lightBlue);
  height: 2px;
  margin: 0px 14px;
  position: relative;
}
.sidebar-icon {
  position: absolute;
  right: -30px;
  top: -12px;
  cursor: pointer;
}
.menu-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 138px);
  justify-content: space-between;
}
.navlinkbase {
  height: 22px;
  margin-top: 40px;
  align-items: center;
  margin-left: 24px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  gap: 18px;
  /* justify-content: space-between; */
}
.profile{
  display: flex;
  gap:20px;
  cursor: pointer;
}
.menu-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.menu-text {
  color: var(--lightGrayText);
  font-size: 14px;
  font-weight: 400;
  text-wrap: nowrap;
  /* opacity: 1; */
  transition: opacity 0.3s ease-in-out;
}
.profile-container {
  /* border: 1px solid green; */
  position: relative;
  transition: margin 0.5s;
}
.user-profile {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dots {
  margin-left: 2px;
  cursor: pointer;
}
.userName {
  font-size: 16px;
  font-weight: 500;
  text-wrap: nowrap;
}
.user-role {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 500;
}
.dialog {
  position: absolute;
  width: 190px;
  bottom: 70px;
  left: 0;
  padding: 18px;
  border-radius: 18px;
  background-color: white;
  border: 1px solid var(--lightBlue);
}
.dialog-list {
  cursor: pointer;
  width: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}
.custom-modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding:15px 20px;
}
.custom-modal-content2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px;
}
.modal-title-cls {
  color: var(--color-primary);
  font-size: 25px;
  font-weight: 600;
}
.modal-content {
  max-width: 550px;
  height: 550px !important;
}
.custom-profile .modal-content {
  max-width: 550px;
  height: 420px !important;
}
.custom-profile2 .modal-content {
  max-width: 550px;
  height: 500px !important;
}
.facli-list {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.fac-name {
  color: #151c2d;
  font-size: 16px;
  font-weight: 500;
}
.delete-fac {
  cursor: pointer;
}
.modal-body-content2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.body-content1 {
  width: 55%;
  margin-top: 16px;
  word-break: break-all;
}
.body-content2 {
  width: 38%;
  margin-top: 16px;
}
.conten1-label {
  color: var(--color-primary-black);
  font-size: 14px;
  font-weight: 500;
}
.conten1-value {
  color: var(--grayText-color);

  font-size: 16px;
  font-weight: 500;
}
.change-password {
  margin-top: 80px;
}
.selectFacilitatorModal{
  height: fit-content;
  color: #5F6583; 
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; 
  align-items: flex-start;
  gap:2px;
}
.filtered-email-inner{
  height: fit-content;
  margin: 15px 0 0 0;
}
.filtered-email{
  display: flex;
  flex-direction: column;
}
hr{
  margin: 0;
}
