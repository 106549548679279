@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  margin-bottom: 0px !important;
}

:root {
  --color-primary-black: #151c2d;
  --color-black: #172a3a;
  --color-primary: #3BB14A;
  --lightBlue: #e6e6fc;
  --gray: #8a8a8a;
  --lightGrayText: #b1b6cf;
  --grayText-color: #7c7c7c;
  --secondary-backgropund: #788b9a;
  --error-color: #ff2626;
  --success-color: #48D662;
  --light-error-color: #ffc3c3;
  --light-success-color:#9CF9AD;
  --disable-primary:#A3C0FF;
 
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background:var(--color-primary);
}
.PhoneInputCountrySelect option {
  border: 1px solid red !important;
}
/* .PhoneInputCountrySelect */


input[type='tel'] {
  background: transparent !important;
  border-color: transparent !important;
  height: 44px !important;
  font-size: 16px;
}

input[type='tel']:focus-visible {
  outline: none !important;
}

input[type='tel']:disabled {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.38);
}

.PhoneInputCountryIcon {
  width: 36px !important;
  height: 20px !important;
}

.PhoneInputCountryIconImg {
  border-radius: 7px !important;
}

.PhoneInputCountryIcon--border {
  box-shadow: none !important;
  background-color: transparent !important;
}
select {
  border-radius: 8px !important;
}
option {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-size: 16px !important;
  border-bottom: 1px solid #e6e6fc !important;
}
option:hover{
  background: #b1b6cf !important;
}

