.Toastify__toast {
  padding: 0px !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 10px 0px rgba(224, 227, 237, 0.80);
}

.Toastify__toast-body {
  padding: 0px !important;
  height: auto !important;
  font-weight: 450 !important;
  font-size: 15px !important;
}

.Toastify__close-button {
  align-self: center !important;
  height: 100%;
  padding-right: 12px !important;
}
.content-text{
  width: 75% !important;
}
.toast-error {
  display: flex;
  align-items: center;
  height: auto;
  color: var(--error-color) !important;
}
.error-icon {
    width: 20px;
    height: 20px;
    margin-left: 22px;
    margin-right: 10px;
 
  display: flex;
  align-items: center;
}
.line {
  width: 6px;
  height: 100%;
  background-color: var(--light-error-color);
}
.lineSuccess{
   width: 6px;
  height: 100%;
  background-color: var(--light-success-color);
}
.toast-success{
 display: flex;
  align-items: center;
  height: auto;
  color: var(--success-color) !important;
}

