.form-container {
  padding: 44px 16px 10px 16px;
  width: 100%;
}
.form-content {
  padding: 0px 138px;
  height: calc(100vh - 115px);
  overflow-y: auto;
}
.form-heading {
  color: var(--color-primary-black);
  font-size: 30px;
  font-weight: 700;
}
.form-subHeading {
  color: var(--color-primary-black);
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
}
.form-fields {
  margin-top: 28px;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}
.form-field {
  width: 32%;
}
.form-field-farm{
  width: 32%;
}
/* .phone-content{
  display: flex;
  flex-direction: column;
} */
.form-content::-webkit-scrollbar {
  width: 15px;
}

.form-content::-webkit-scrollbar-track {
  border-radius: 12px;
  background: var(--lightBlue);
}

.form-content::-webkit-scrollbar-thumb {
  border-radius: 12px;
  width: 3px;
  height: 100px !important;
  background: var(--color-primary);
}
.bottom-border-line {
  margin-top: 40px;
  width: 100%;
  height: 1px;
  background: var(--lightBlue);
}
.buttons-end {
  /* margin-top: 80px; */
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.handle-check-input {
  position: static;
  height: 14px;
  width: 14px;
}
.file-container {
  cursor: pointer;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  width: 99%;
  border-radius: 8px;
  border: 2px dashed #e6e6fc;
}
.input-form-container {
  width: 100%;
}
.file-text {
  color: #3bb14a;
  font-size: 20px;
  font-weight: 600;
}
.display-list {
  margin-top: 24px;
  width: 100%;
}

.title-text {
  color: #7c7c7c;
  font-size: 14px;
  font-weight: 500;
}
.title-text-docusign {
  color: #7c7c7c;
  font-size: 14px;
  font-weight: 500;
  width: 20%;
}
.title-text-date {
  width: 9%;
  color: #7c7c7c;
  font-size: 14px;
  font-weight: 500;
}
.display-list2 {
  display: flex;
  gap: 100px;
  align-items: center;
}
.delete_icon {
  margin-bottom: 18px;
}
.delete_icon img {
  width: 100%;
  height: 100%;
}
.inner-text {
  width: 20%;
  margin-bottom: 18px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.inner-text-date {
  width: 8%;
  margin-bottom: 18px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
}
.display-list1 {
  display: flex;
  gap: 85px;
  margin-bottom: 18px;
}
.docuSign-container {
  margin-top: 40px;
}
.docusign-view {
  cursor: pointer;
  color: #3bb14a;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}
.condition-text {
  color: #414141;
  font-size: 11px;
  font-weight: 500;

  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
.service-text {
  cursor: pointer;
  color: #2563eb;
  font-size: 11px;
  font-weight: 500;
  text-decoration-line: underline;
}
.contract-text{
  margin-top: 15px;
  color: #3bb14a;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 1536px) {
  .title-text-date {
    width: 11%;
  }
  .inner-text-date {
    width: 10%;
  }
  .inner-text {
    width: 40%;
  }
  .title-text-docusign{
    width: 25%;
  }
}
@media (max-width: 1410px) {
  .form-field {
    width: 31%;
  }
  .form-field-farm{
    width: 48%;
  }
  .display-list2 {
    gap: 60px;
  }
  .title-text-date {
    width: 15%;
  }
  .inner-text-date {
    width: 14%;
  }
  .inner-text {
    width: 40%;
  }
}
@media (max-width: 1120px) {
  .title-text-date {
    width: 18%;
  }
  .inner-text-date {
    width: 18%;
  }
  .inner-text {
    width: 50%;
  }
}
@media (max-width: 980px) {
  .form-content {
    padding: 0px 70px;
  }
  .form-field {
    width: 48%;
  }
  .form-field-farm{
    width: 48%;
  }
  .display-list2 {
    gap: 50px;
  }
  .title-text-date {
    width: 22%;
  }
  .display-list1 {
    gap: 35px;
  }
  .inner-text-date {
    width: 20%;
  }
  .inner-text {
    width: 60%;
  }
}
@media (max-width: 720px) {
  .form-field {
    width: 98%;
  }
  .form-field-farm{
    width: 98%;
  }
  .display-list2 {
    gap: 20px;
  }
  .display-list1 {
    gap: 20px;
  }
  .inner-text {
    font-size: 14px;
  }
  .inner-text-date {
    font-size: 14px;
  }
  .inner-text {
    width: 60%;
  }
  .inner-text-date {
    width: 20%;
  }
  .delete_icon {
    width: 3%;
  }
}
@media (max-width: 500px) {
  .form-content {
    padding: 0px 20px;
  }
  .file-text {
    font-size: 16px;
  }
  .display-list2 {
    gap: 10px;
    width: 100%;
  }

  .delete_icon {
    width: 20px;
    height: 20px;
  }
  .title-text-date {
    width: 22%;
  }
  .inner-text-date {
    width: 26%;
  }
  .inner-text {
    width: 60%;
    overflow-wrap: break-word;
  }
}
