.modal-content1 {
  position: absolute;
  height: auto;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  width: 402px;
  padding: 20px;
}
.modal-inner {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  width: 100%;
  right: 15px;
  top: 15px;
}
.modal-inner img {
  cursor: pointer;
  height: 18px;
  width: 18px;
}
.modal-newText {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: 22px;
  padding-bottom: 24px;
  color: var(--color-primary);
}
.modal-newText img {
  margin-right: 10px;
}
.validation-text {
  color: red;
  font-size: 12px;
}
.cancle-modal {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  gap: 15px;
}
.cancle-modal-content {
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  background-color: #788b9a;
  cursor: pointer;
  height: 40px;
  color: #fff;
  border-color: 1px;
  color: 16px;
  border-radius: 6px;
}
/* .cancle-modal-content:hover {
  background: #788B9A;
} */
.add-content {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  background: #3bb14a;
  color: 16px;
  border-radius: 6px;
}

.input-field-dropdown {
  position: relative;
}

.main-select-container {
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
  gap: 0.25rem;
  height: auto;
  background-color: #ffffff;
  padding: 10px;
  padding-right: 30px;
  border-color: 1px;
  border: 1px #e6e6fc solid;
  color: var(--grayText-color);
  border-radius: 8px;
  padding: 13px 20px;
}
.select-contain {
  display: flex;
  align-items: center;
  height: 25px;
  background: #e1e4f0;
  color: #70727e;
  padding: 2px 4px;
  border-radius: 5px;
}
.close-icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
}
.toggle-dropdown {
  position: absolute;
  right: 0.75rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  cursor: pointer;
  top: 15px;
}
.select-email-content {
  display: flex;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-color: 1px;
  height: 52px;
  border: 1px #e6e6fc solid;
  color: var(--grayText-color);
  border-radius: 8px;
  padding: 13px 20px;
}
.select-placeholder {
  display: flex;
  width: 100%;
  color: 15px;
  color: #5f6583;
  gap: 8px;
}
.handle-input {
  position: absolute;
  z-index: 30;
  background-color: #ffffff;
  width: 100%;
  border-color: 1px;
}
.input-handle-number {
  outline: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px #e6e6fc solid;
  height: 40px;
  padding-left: 40px;
  border-bottom: gray;
  color: #5f6583;
  border-top-left-radius: 0.25rem; /* 4px */
  border-top-right-radius: 0.25rem; /* 4px */
}
.search-modal {
  position: absolute;
  top: 11px;
  left: 16px;
}
.handle-check-content {
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 0.25rem; /* 4px */
  border-bottom-right-radius: 0.25rem;
  border: 1px #e6e6fc solid;
}
.handle-check-content-inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding-left: 15px;
}
.handle-check-input {
  position: static;
  height: 14px;
  width: 14px;
}
.filtered-email {
  overflow-y: auto;
  max-height: 145px;
}
.filtered-email-inner {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  padding-left: 15px;
  color: 14px;
}

.modal-content2 {
  position: absolute;
  height: auto;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  width: 550px;
  height: 550px;
  padding: 20px;
}
.cancle-modal-faci {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.react-tagsinput {
  border-radius: 8px !important;
  border: 1px #e6e6fc solid !important;
  color: var(--grayText-color) !important;
  outline: none !important;
  color: #7c7c7c !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.react-tagsinput-tag {
  height: 25px !important;
  background: #e1e4f0 !important;
  color: #70727e !important;
  padding: 2px 4px !important;
  border-radius: 5px !important;
  border: none !important;
  font-size: 14px !important;
  font-family: Poppins !important;
}
.react-tagsinput-input{
   color: #7c7c7c !important;
  font-family: Poppins !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  width: 100% !important;
}
