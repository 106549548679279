.broker-view-container{
  height: 100%;
  padding: 0px 30px;
}
.filters-and-map{
    display: flex;
    gap:20px;
    height: 350px;
}
.filters{
    width: 50%;
}
.address-fields{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap:10px;
}
.other-fields{
    margin-top: 10px;
}
.dropdown-fields{
  margin-top: 10px;
}
.geo-map{
  width: 100%;
  position: relative;
}
.clear-button{
  width:12%;
  text-align: center;
  height:51px;
  position: relative;
  top:20px;
  display: grid;
  place-items: center;
  background-color: #3bb14a;
  color:#fff;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
}