.farm-container{
    height: 100%;
    padding-left: 30px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.fields{
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
}
.field{
    width: 32%;
    /* border: 1px solid black; */
}
.field-dropdown-tag{
    width: 32%;
}
.buttons{
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-bottom: 40px;
    margin-right: 20px;
}
.custom-confirm-modal{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.confirm-modal-header{
    color: var(--color-primary);
    font-size: 25px;
    font-weight: 600;
    width: 100%;
    background-color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top:0;
    padding:15px;
    z-index:1;
}
.confirm-modal-footer{
    width: 100%; 
    bottom:0;
    display: flex;
    justify-content: flex-end;
    padding:15px;
}
.modal-body{
    padding:0!important;
}
.modal-content{
    max-width: 100%!important;
    overflow-y: scroll;
}
.confirm-modal-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.label-text{
    width:40%;
    font-size: 16px;
    font-weight: 500;
}
.confirm-modal-container input{
    color: #000 !important;
    width:450px!important;
}
.confirm-modal-body{
    display: flex;
    flex-direction: column;
    gap:10px;
    padding:0 15px;
}

@media(max-width:1440px){
    .field{
        width: 31%;
    }
    .field-dropdown-tag{
        width: 48%;
    }
}

@media(max-width:1066px){
    .field{
        width: 47%;
    }
    .field-dropdown-tag{
        width: 47%;
    }
}
@media (max-width: 980px) {
    .field-dropdown-tag{
        width: 48%;
    }
}
@media(max-width:720px){
    .field-dropdown-tag{
        width: 98%;
    }
}