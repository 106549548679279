.Add-icon{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.Add-icon img {
    cursor: pointer;
}
.animal-component{
    display: flex;
    gap:'10px'
}
.delete-icon-animal{
    align-self:end ;
    padding:5px;
     cursor:pointer;
}
.phone-input-container{
    border:1px #e6e6fc solid;
    background-color: #fff;
    display: flex;
    padding:13px 10px;
    margin-top: 6px;
    border-radius:8px;
    gap:10px;
}
.phone-input-container input{
    background: #fff;
    border: none;
}