.login-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 82vh;
}
.main-container {
  background: url(../../../images/Auth/unsplash.jpg) no-repeat center;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
}
.login-footer {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  margin-top: 10px;
}
.inner-container {
  display: flex;
  gap: 40px;
  width: 100vw;
  justify-content: space-between;
  margin: 0 120px 0 80px;
}
.footer {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}
.mail {
  color: #3bb14a !important;
  text-decoration: underline;
}
.container2 {
  color: #3bb14a !important;
  min-width: 740px;
  text-align: left;
}
.heading-content {
  font-size: 24px;
  font-weight: 600;
}
.heading-list {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.heading-list li {
  font-size: 18px;
  font-weight: 500;
}
.forgot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.container1 {
  max-width: 400px;
  margin-right: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.logo {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}
.logo img{
  transform: scale(1.5);
}
.content {
  padding: 33px;
  width: 400px;
}
.login {
  color: var(--color-primary-black) !important;
  font-size: 20px;
  font-weight: 600;
}
.Fileds {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
}
.text {
  /* width: 192px; */
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
}
.label-text {
  color: var(--color-primary-black) !important;
  font-size: 14px;
  font-weight: 400;
}
.inputField input {
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px #e6e6fc solid;
  padding: 11px 13px;
}
.password {
  display: flex;
  justify-content: space-between;
}
.label-forget {
  color: var(--gray);
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  cursor: pointer;
}
.button-component {
  margin-top: 30px;
}
.back-button {
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  gap: 6px;
  width: max-content;
  margin: auto;
  margin-top: 20px;
}
