.button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 6px;
    cursor: pointer;
}
.button-arrow{
    gap: 8px;
}