.contact-container {
  height: 100%;
  padding: 10px 30px;
}
.heading-text {
  font-size: 16px;
  font-weight: 500;
}
.contact-fields {
  margin-top: 28px;
  width: 64%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.conatact-field {
  margin-top: 10px;
  width: 48%;
}
.privacy-text {
  color: var(--gray);
  font-size: 16px;
  font-weight: 500;
}
.privacy {
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}
.checkbox-input{
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
}
.checkbox-input input{
  width: 18px !important;
  height: 18px !important;
  border-radius: 3px !important;
border: 1.5px solid #E6E6FC !important;
}

.checkbox-input input[id="checkbox1"] {
border-radius: 10px !important;
border: 1.5px solid #E6E6FC !important;
cursor: pointer;
outline: none !important;
text-decoration: none;
}

/* input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #E23844;
  border: 1px solid #E23844;
  border-radius: 5px;
  transform: translateY(-0.075em);
}

input[type="checkbox"]:checked::before {
  content: '\2713';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #E23844;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  line-height: 1;
  color: white;
} */
.send-button{
  margin-top: 60px;
  padding-bottom: 20px;

}
@media(max-width:1440px){
  .send-button{
    margin-top: 40px;
  }
}
@media(max-width:1140px){
  .contact-fields{
    width: 72%;
    gap: 14px;
  }
}
@media(max-width:980px){
  .contact-fields{
    width: 72%;
    gap: 5px;
  }
  .conatact-field{
    width: 98%;
  }
}
