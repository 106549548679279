.reject-title-cls {
  color: var(--color-primary);
  font-size: 20px;
  font-weight: 500;
}
.reject-body-content {
  margin-top: 30px;
}
.reject-modal .modal-content {
  height: 450px !important;
}
.buttons-reject {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
.deletion-modal .deletion-modal {
  height: 300px !important;
}
.confirm-text {
  margin-top: 20px;
  color: var(--grayText-color);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.delete-modal .modal-content {
  height: 350px !important;
  width: 460px !important;
}
.delete-modal .modal-dialog {
  justify-content: center !important;
}
.error-msg {
  color: var(--error-color);
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}
