.InputField {
  position: relative;
  margin-top: 4px;
}
.InputField input {
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px #e6e6fc solid;
  padding: 11px 13px;
  color: var(--color-primary);
  font-size: 12px;
  font-weight: 400;
}
/* .InputField input {
} */
.password-display {
  position: absolute;
  top: 23%;
  right: 13px;
  cursor: pointer;
}

.input-box {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.label {
  color: var(--color-primary-black);
  font-size: 16px;
  font-weight: 500;
  display: flex;
}
.required {
  color: #ff2626;
}
.input-field {
  position: relative;
}
.input-field input {
  background: white;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px #e6e6fc solid;

  color: var(--grayText-color);
  font-size: 16px;
  font-weight: 500;
}
.main-div {
  cursor: pointer;
  position: relative;
  height: 52px;
  width: 100%;
}
.innerDiv {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px #e6e6fc solid;
  padding: 13px 20px;
  color: var(--grayText-color);
  font-size: 16px;
  font-weight: 500;
}
.dropdown-icon {
  position: absolute;
  top: 23%;
  right: 13px;
  cursor: pointer;
}
.arrow_img {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.dialog-container {
  min-height: 45px;
  max-height: 350px;
  overflow-y: auto;
  z-index: 20;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  position: absolute;
  border-radius: 8px;
  border: 1px solid #e6e6fc;
  background: #fff;
  top: 55px;
  left: 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
.list-item {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--color-primary-black);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.textarea-container textarea {
  width: 100%;
  outline: none;
  border-radius: 8px;
  border: 1px #e6e6fc solid;
  padding: 13px 20px;
  color: var(--grayText-color);
  font-size: 16px;
  font-weight: 500;
  resize: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.pac-container:after {
  content: none !important;
}

.form-control:focus {
  border: 1px #e6e6fc solid !important;
  outline: none !important;
  box-shadow: none !important;
  color: var(--grayText-color) !important;
}
.pac-container {
  background: #fff !important;
  border: 1px solid #e6e6fc !important;
  border-radius: 8px !important;
  box-shadow: 0 3px 10px 0 #0000001a !important;
}
.pac-item{
  padding: 4px 4px !important;
      color: var(--color-primary-black) !important;
    font-size: 16px !important;
    /* font-weight: 500 !important; */
}
.custom-tooltip {
  max-width: 50vw; /* Adjust this value as needed */
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}
