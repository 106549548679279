.email-text {
  color: var(--color-primary);
  font-weight: 500;
}
.success-content {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: var(--color-primary-black);
}
.success-text {
  margin-top: 40px;
}
.resend-code-text {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  margin-right: 6px;
  text-align: end;
}
.code-text {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-black);
}
.resend-text {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 500;
}
