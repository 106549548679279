.navbar-container {
  width: 100%;
  height: 130px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project-select {
  position: relative;
  width: 320px;
  border-radius: 12px;
  border: 1px solid #e6e6fc;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-primary-black);

  font-size: 14px;
  font-weight: 600;
}
.header-text {
  color: var(--color-primary-black);
  font-size: 30px;
  font-weight: 700;
}
.project-image {
  display: flex;
  justify-content: center;
  align-items: center;
      width: 20%;
    height: 45px;
}
.project-content {
  width: 60%;
}
.down-arrow {
  cursor: pointer;
  width: 10%;
}
.arrow_img {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.down-arrow-list {
  cursor: pointer;
  width: 8%;
}
.project-list-dialog {
  width: 100%;
  z-index: 30;
  border-radius: 12px;
  position: absolute;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
.project-selects {
  border-radius: 12px;
  padding: 0px 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-primary-black);
  width: 100%;
}
.border-line {
  width: 94%;
  height: 1px;
  background-color: #e6e6fc;
}
.open-text {
  min-width: 36px;
  width: 36px;
  color: var(--color-primary);
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.loading-spin{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 980px) {
  .header-text {
    font-size: 26px;
  }
}
