.layout-container{
    width: 100%;
    height: 100vh;
    display: flex;

}
.sidebar{
    height: 100vh;
    transition: width 0.3s ease-in-out;
}
.right-container{
    display: flex;
    flex-direction: column;
}
.outlet-container{
    height: calc(100vh - 150px);
    overflow-y: auto;
}