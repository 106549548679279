.facilitator-container {
  height: 100%;
  padding-left: 30px;
  padding-right: 16px;
}
.search-box {
  width: 42%;
}
.search-input-field {
  position: relative;
}
.search-input-field input {
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px #e6e6fc solid;
  color: var(--grayText-color);
  font-size: 16px;
  font-weight: 500;
  padding: 12px 13px;
}
.search-img {
  position: absolute;
  right: 14px;
  top: 17px;
}
.table-container {
  margin-top: 40px;
  width: 100%;
  margin-right: 16px;
}
.table-header {
  display: flex;
  gap: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 14px;
  color: var(--secondary-backgropund);
  font-size: 16px;
  font-weight: 600;
}
.facilator-head {
  width: 17%;
  overflow-wrap: break-word;
}
.project-head {
  width: 18%;
  overflow-wrap: break-word;
}
.payout-head {
  width: 18%;
  overflow-wrap: break-word;
}
.paidout-head{
  width: 18%;
  overflow-wrap: break-word;
}
.farm-head {
  width: 17%;
  overflow-wrap: break-word;
}
.docusign-head {
  width: 17%;
  overflow-wrap: break-word;
  display: flex;
  gap: 6px;
  /* align-items: center; */
}
.farmForm-head {
  width: 14%;
  overflow-wrap: break-word;
}
.reminder-head {
  width: 14%;
  overflow-wrap: break-word;
}
.link-head {
  width: 16%;
  overflow-wrap: break-word;
}
.border-bottom {
  background: var(--lightBlue);
  width: 97%;
  height: 1px;
}
.table-body {
  padding-left: 10px;
  padding-right: 10px;
  height: calc(100vh - 380px);
  overflow-y: auto;
}
.spin-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-row {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 15px;
  gap: 20px;
}
.link-row {
  color: var(--color-primary);
}
.delete_button {
  cursor: pointer;
  width: 16px;
  height: 22px;
  margin-top: -2px;
}
.no-data {
  width: 100%;
  color: var(--secondary-backgropund);
  font-size: 24px;
  font-weight: 500;
  margin-top: 10%;
  text-align: center;
}
.pagination-content {
  padding-left: 10px;
  padding-right: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.page-text {
  color: var(--color-primary);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.hidden-class {
  visibility: hidden;
}
.block-class {
  visibility: visible;
}
.custom-tooltip {
  max-width: 50vw; /* Adjust this value as needed */
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}
