.carbon-container{
    height: 100%;
  padding: 10px 30px;
}
.sub-fields{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    gap:20px;
}
.geo-map{
  margin-top: 20px;
}
.carbon-cards{
  display: flex;
  gap:20px;
  margin-top: 20px;
}
.custome-card{
  width:100%;
}
.Filter-field{
  display: flex;
  justify-content: space-between;
  gap:30px;
}
.clear-button{
  width:12%;
  text-align: center;
  height:51px;
  position: relative;
  top:30px;
  display: grid;
  place-items: center;
  background-color: #3bb14a;
  color:#fff;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
}
.custome-card {
  width: 32%;
  border-radius: 14px;
  border: 1px solid var(--lightBlue);
  background: #fff;
  padding: 20px 46px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-name{
  font-size: 18px;
  font-weight: 500;
}
.card-count{
  color: var(--color-primary);

font-size: 40px;
font-weight: 600;
}
.table-container-analytics {
  margin-top: 40px;
  width: 42%;
  border: 1px solid var(--lightBlue);
  border-radius: 14px;
  margin: 40px auto;
  /* height: calc(100vh - 340px); */
}
.table-body-analytics {
  padding-left: 30px;
  padding-right: 30px;
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}
.border-bottom-analytics {
  background: var(--lightBlue);
  width: 100%;
  height: 1px;
}
.facilator-head-analytics {
  width: 70%;
  overflow-wrap: break-word;
}
.farm-head-analytics {
  width: 30%;
  overflow-wrap: break-word;
}
.table-row-analytics {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 15px;
  gap: 20px;
}